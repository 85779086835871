// import axios from "axios";

const state = {
  selectedProduct: null,
  editedProduct: null,
  cart: [],
  inclusive: {
    status: false,
    taxes: [],
  },
  delivery: [],
  voucher: null,
  selectedHistory: null,
};

const getters = {
  getSelectedProduct: (state) => state.selectedProduct,
  getInclusive: (state) => state.inclusive,
  getCart: (state) => state.cart,
  getDelivery: (state) => state.delivery,
  getVoucher: (state) => state.voucher,
  getSelectedHistory: (state) => state.selectedHistory,
};

const actions = {
  updateSP({ state }, value) {
    state.selectedProduct = value;
  },
  editSP({ state }, value) {
    value.value.newProd = 1;
    state.selectedProduct = value.value;
    state.editedProduct = value;
  },
  updateSH({state}, value){
    state.selectedHistory = value;
  },
  removeSH({state}){
    state.selectedHistory = null
  },
  plus({ state }, v) {
    state.cart[v].quantity++;
  },

  updateCart({ state }, value) {
    state.cart = value;
  },

  removeCart({ state }, value) {
    state.cart = [
      ...state.cart.slice(0, value),
      ...state.cart.slice(value + 1, state.cart.length),
    ];
  },

  findVoucher({ dispatch, state }, value) {
    return new Promise((res) => {
      dispatch("fetch", {
        url: `/vouchers/${value.voucher}`,
        method: "get",
      }).then((response) => {
        if (!response.data.error) {
          if (response.data.min && value.total >= response.data.min) {
            state.voucher = response.data;
            state.voucher.id = response.data._id;
          } else {
            state.voucher = null;
          }
        }
        res(response.data);
      });
    });
  },

  cartTotal({ state }) {
    return new Promise((res) => {
      let a = state.cart.reduce((sum, item) => {
        if (item.taxes && item.taxes.length > 0) {
          state.inclusive.taxes = item.taxes;
          state.inclusive.status = item.taxes[0].inclusive;
        }
        var mods = item.modifiers.reduce((sum, mod) => {
          var amt = parseFloat(mod.amount);
          var actual = mod.mode == 1 ? amt : mod.mode == 2 ? -amt : 0;
          return parseFloat(sum) + actual;
        }, 0);
        if (item.variant[0]) {
          var vars = item.variant.reduce((sum, vary) => {
            var amt = parseFloat(vary.price);
            return parseFloat(sum) + amt;
          }, 0);
        }
        var variants = vars ? vars : 0;
        var tax = 0;

        // if (item.taxes && item.taxes.length > 0 && item.taxes[0].inclusive) {
        //   tax = item.taxes.reduce((sum, data) => {
        //     let a =
        //       Math.floor(
        //         parseFloat(item.unit_price).toFixed(2) * parseInt(data.amount)
        //       ) / 100;
        //     sum += a;
        //     return sum;
        //   }, 0);
        // }
        return (
          parseFloat(sum) +
          (parseFloat(item.price_type == 0 ? 0 : item.unit_price) +
            parseFloat(mods) +
            parseFloat(item.price_type != 0 ? 0 : variants)) *
            item.quantity +
          tax
        );
      }, 0);
      res(a);
    });
  },

  fetchPickup({ state }) {
    state.delivery = [
      {
        name: "Self Pickup",
        provider: null,
        data: null,
      },
    ];
  },

  fetchOwnDelivery({ state, rootGetters }) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      let push = () => {
        let a = state.delivery.findIndex((d) => d.provider == "own_delivery");
        if (a == -1) {
          state.delivery.push({
            name: "In-House Delivery",
            provider: "own_delivery",
            data:
              {
                delivery_fee: f.delivery_fees || 0,
              } || null,
          });
        } else {
          state.delivery[a].data =
            {
              delivery_fee: f.delivery_fees || 0,
            } || null;
        }
      };
      push();
      res(true);
    });
  },

  async fetchDelyvaFees({ state, dispatch, rootGetters }, cust) {
    let f = rootGetters["getFranchise"];
    let o = rootGetters["getSelectedOutlet"];

    let oGeo = f.outlets.find(
      (d) => d.name == o
    );

    let mercGeo = {
      address: (oGeo) ? oGeo.address : f.address,
      address2: (oGeo) ? oGeo.address2 : f.address2
    };
    let fGeo = await dispatch("fetchGeocode", mercGeo);

    let custGeo = {
      address: cust.address,
      address2: cust.address2
    };
    let cGeo = await dispatch("fetchGeocode", custGeo);

    let weight = state.cart.reduce(function(sum, item) {
      if (item.weight) {
        return sum = sum+item.weight.converted * item.quantity;
      } else {
        return sum = sum+0.5 * item.quantity;
      }
    },0);

    new Promise((res) => {
      var s = {
        pickup_address1: `${fGeo.address}`,
        pickup_address2: `${fGeo.address2}`,
        dropoff_address1: `${cust.address}`,
        dropoff_address2: `${cust.address2}`,
        pickup_city: `${fGeo.city}`,
        pickup_state: `${fGeo.state}`,
        pickup_pc: `${(oGeo) ? oGeo.postcode : f.postcode}`,
        dropoff_city: `${cGeo.city}`,
        dropoff_state: `${cGeo.state}`,
        dropoff_pc: `${cust.postcode}`,
        pickup_long: fGeo.longitude,
        pickup_lat: fGeo.latitude,
        dropoff_long: cust.lng ? cust.lng : cGeo.longitude,
        dropoff_lat: cust.lat ? cust.lat : cGeo.latitude,
        fire_id: f.fire_id,
        total_weight: weight
      };

      dispatch("fetch", {
        url: "/guest/delyvax/calculate",
        method: "post",
        data: s,
      }).then((r) => {
          let push = (r) => {
            if (r.data.data.services.length != 0) {
              var p = r.data.data.services
              for (let [key, value] of Object.entries(p)) {
                let r = {delivery_fee: value.price.amount};
                let a = state.delivery.findIndex((d) => d.name == value.service.name);
                if (a == -1) {
                  state.delivery.push({
                    name: value.service.name,
                    provider: "delyvax",
                    code: value.service.code,
                    data: r || null,
                    key: key
                  });
                } else {
                  state.delivery[a].data = r || null;
                }
              }
            } else {
              let c = state.delivery.findIndex((d) => d.name == "unavailable");
              if (c == -1) {
                state.delivery.push({
                  name: 'unavailable', //check name
                  provider: "delyvax" //check provider
                });
              } else {
                state.delivery;
              }
            }
          };
        push(r);
        res(r.data);
      });
    });
  },

  fetchSpeedyFees({ state, dispatch, rootGetters }, value) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      // let o/bj = {};
      let push = (r) => {
        if (state.delivery.length > 1) {
          let a = state.delivery.findIndex((d) => d.provider == "speedy");

          if (a == -1) {
            state.delivery.push({
              name: "MrSpeedy Delivery",
              provider: "speedy",
              data: r || null,
            });
          } else state.delivery[a].data = r || null;
        } else
          state.delivery.push({
            name: "MrSpeedy Delivery",
            provider: "speedy",
            data: r || null,
          });
      };

      push();
      var s = value;
      s.coords = [];
      dispatch("fetch", {
        url: "/guest/calculate",
        method: "post",
        data: { guest: s },
      }).then((r) => {
        if (f.delivery_cap) {
          let cap = Number(f.delivery_cap);
          if (r.data.delivery_fee < cap) {
            r.data = { delivery_fee: cap, exceeded: r.data.exceeded };
          }
        }

        push(r.data);
        res(r.data);
      });
    });
  },

  fetchBungkusitFees({ state, dispatch, rootGetters }, value) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      // let o/bj = {};
      let push = (r) => {
        if (state.delivery.length > 1) {
          let a = state.delivery.findIndex((d) => d.provider == "bungkusit");
          if (a == -1) {
            state.delivery.push({
              name: "Bungkusit Delivery",
              provider: "bungkusit",
              data: r || null,
            });
          } else state.delivery[a].data = r || null;
        } else
          state.delivery.push({
            name: "Bungkusit Delivery",
            provider: "bungkusit",
            data: r || null,
          });
      };
      push();
      var s = {
        pickup_address: `${f.address}, ${f.address2}`,
        dropoff_address: `${value.address}, ${value.address2}`,
        order_type: "DELIVER_NOW",
        pickup_long: f.longitude,
        pickup_lat: f.latitude,
        dropoff_long: value.longitude,
        dropoff_lat: value.latitude,
        fire_id: f.fire_id,
        cust_name: value.name,
        cust_phone: value.phone,
        notes: "",
      };
      dispatch("fetch", {
        url: "/guest/bungkusit/calculate",
        method: "post",
        data: s,
      }).then((r) => {
        if (f.delivery_cap) {
          let cap = Number(f.delivery_cap);
          if (r.data.delivery_fee < cap) {
            r.data = { delivery_fee: cap, exceeded: r.data.exceeded };
          }
        }
        push(r.data);
        res(r.data);
      });
    });
  },

  fetchLalamoveFees({ state, dispatch, rootGetters }, value) {
    let f = rootGetters["getFranchise"];
    new Promise((res) => {
      // let o/bj = {};
      let push = (r) => {
        if (state.delivery.length > 1) {
          let a = state.delivery.findIndex((d) => d.provider == "lalamove");
          if (a == -1) {
            state.delivery.push({
              name: "Lalamove Delivery",
              provider: "lalamove",
              data: r || null,
            });
          } else state.delivery[a].data = r || null;
        } else
          state.delivery.push({
            name: "Lalamove Delivery",
            provider: "lalamove",
            data: r || null,
          });
      };
      push();
      var s = {
        pickup_address: `${f.address}, ${f.address2}`,
        dropoff_address: `${value.address}, ${value.address2}`,
        order_type: "DELIVER_NOW",
        pickup_long: f.longitude,
        pickup_lat: f.latitude,
        dropoff_long: value.longitude,
        dropoff_lat: value.latitude,
        fire_id: f.fire_id,
        cust_name: value.name,
        cust_phone: value.phone,
        notes: "",
      };
      dispatch("fetch", {
        url: "/guest/lalamove/calculate",
        method: "post",
        data: s,
      }).then((r) => {
        if (f.delivery_cap) {
          let cap = Number(f.delivery_cap);
          if (r.data.delivery_fee < cap) {
            r.data = { delivery_fee: cap, exceeded: r.data.exceeded };
          }
        }
        push(r.data);
        res(r.data);
      });
    });
  },
};

const mutations = {
  addToCart(state, value) {
    if (state.editedProduct != null && value.newProd == true) {
      state.cart[state.editedProduct.index] = value;
    } else {
      const change = (cart, val) => {
        let a = null;
        cart.forEach((d, i) => {
          d[val].forEach((k, ind) => {
            if (k.id == value[val][ind].id) {
              a = i;
            }
          });
        });
        if (a == null) {
          state.cart.push(value);
        } else {
          if (val.modifiers && value.modifiers.length > 0) {
            if (val.modifiers.name == value.modifiers.name) {
              state.cart[a] = value;
            } else {
              state.cart.push(value);
            }
          }

          if (val.variant && value.variant.length > 0) {
            if (val.variant.name == value.variant.name) {
              state.cart[a] = value;
            } else {
              state.cart.push(value);
            }
          }
        }
      };

      if (state.cart.length == 0) {
        state.cart = [value];
      } else if (state.cart.some((d) => d.id == value.id)) {
        if (value.modifiers && value.modifiers.length > 0) {
          state.cart.forEach((d) => {
            if (d.modifiers && d.modifiers.length > 0) {
              change(state.cart, "modifiers");
            }
          });
        }
        if (value.variant && value.variant.length > 0) {
          state.cart.forEach((d) => {
            if (d.variant && d.variant.length > 0) {
              change(state.cart, "variant");
            }
          });
        }
      } else state.cart.push(value);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
