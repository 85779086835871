import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/:child?",
      name: "Home",
      component: () => import("../components/Home/index.vue"),
    },
    {
      path: "/:child?/:page?",
      name: "Page",
      component: () => import("../components/Page/index.vue"),
    },
    //redirect
  ],
});
