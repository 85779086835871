import axios from "axios";
import config from "../../constant";

const state = {
  user: {
    email: "",
    token:
      "eyJhbGciOiJSUzI1NiIsImtpZCI6IjRlOWRmNWE0ZjI4YWQwMjUwNjRkNjY1NTNiY2I5YjMzOTY4NWVmOTQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbmV4dG9yZGVyLWF1dGgiLCJhdWQiOiJuZXh0b3JkZXItYXV0aCIsImF1dGhfdGltZSI6MTYxOTc2NTY1MCwidXNlcl9pZCI6IkJIVEFyeE8wbW5hMXlHMVh6OG9KQ0ZIVkxDcjEiLCJzdWIiOiJCSFRBcnhPMG1uYTF5RzFYejhvSkNGSFZMQ3IxIiwiaWF0IjoxNjE5NzY1NjUwLCJleHAiOjE2MTk3NjkyNTAsImVtYWlsIjoiYWRtaW5AZ2V0b3JkZXJzLmFwcCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJhZG1pbkBnZXRvcmRlcnMuYXBwIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.B3PbLKWvg66_iQQiLmXMzGndTj0h6WtSNrfrBhBtjhJLJD7rgrBtZN-rabHdC3dOT15A-SeiykmOT89t-vKpj74U8k1SJIeZswVlU8QdU_otqnQAO_JzzluM2QIpP8T5uMvGTzqgQ4d-F9Adz0Wn-yBBN_E57qiAPexu0r5N9ln9H9viJqrezcMXgQppdBPuJoYLgipEnm7jeIVNMQYHOrHn__Sleu3qOoETAo0X2lo0HtFHL1RrSLeT4SHiwuqpECLIkCxmEcxKwbbvwRv3ZhN-lyBvyqk_KhAGY-dPRH-1I5lt7BkVZhJtAPASmNIH843J3RqtyPhqB3WeM3AtyA",
    uid: "",
  },
  users: [],
  order:[],
  selectedOutlet: null,
  franchise: null,
  ident: null,
  loading: false,
  errorGeo: false,
  product: null,
  invoice_id: null,
  invoice: null,
  productReview: [],
  scroll: null,
  delivery: [
    {
      name: "Self Pickup",
      provider: null,
      data: null,
    },
  ],
  // tagslist: {}
};

const getters = {
  getLoading: (state) => state.loading,
  getFranchise: (state) => state.franchise,
  getProduct: (state) => state.product,
  // getTags: (state) => state.tagslist,
  getUsers: (state) => state.users,
  getInvoice: (state) => state.invoice,
  getScroll: (state) => state.scroll,
  getProductReview: (state) => state.productReview,
  getOrder: (state) => state.order,

  getSelectedOutlet: (state) => state.selectedOutlet,
  getAuthHeader: (state) => {
    const item = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": "9R03ClUKfaRHP6lkvg4349ZCfWd1XHP3rjQCk023",
      Authorization: state.user.token,
    };
    return item;
  },
};

const actions = {
  startLoad({ state }) {
    state.loading = true;
  },

  endLoad({ state }) {
    state.loading = false;
  },

  updateSO({ state }, value) {
    state.selectedOutlet = value;
  },

  changeScroll({ state }, value) {
    state.scroll = value;
  },

  // fetchTagList({state}, value) {
  //   console.log(state.tagslist, 'taglist');
  //   state.tagslist = value;
  //   state.product.forEach((prod) => {
  //     prod.tags.forEach(function (tag) {
  //       if (!tag.hidden_tag) {
  //           (state.tagslist[tag.id] = tag); 
  //       }
  //     });
  //   });

  //   return Object.values(state.tagslist).sort(function (a, b) {
  //     if (a.name < b.name) {
  //       return -1;
  //     }
  //     if (a.name > b.name) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // },

  fetch({ state }, value) {
    return new Promise((res) => {
      const item = {
        method: value.method,
        url: `https://api.getorders.app${value.url}`,
        data: { ...value.data },
        headers: {},
      };

      if (value.headers) {
        item.headers = { ...value.headers };
      } else {
        item.headers = {
          Code: state.ident || "",
          fire_id: state.franchise ? state.franchise.fire_id : "",
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": "EF95vGTnuo1GDqSQw6BmN41IR1G5R0Li8E5WIxlm",
        };
        if (state.franchise && state.franchise.acc_id) {
          item.headers.acc_id = state.franchise.acc_id;
        }
        if (state.franchise && state.franchise.f_id) {
          item.headers.fran_id = state.franchise.f_id;
        }
        if (state.invoice_id) {
          item.headers.order_id = state.invoice_id;
        }
        if (state.selectedOutlet) {
          let i = state.franchise.outlets.find(
            (d) => d.name == state.selectedOutlet
          );
          item.headers.outlet_id = i.id;
        }
      }

      axios(item).then((d) => {
        if (d.data.status == "failed" && d.data.message == "unauthorize") {
          // router.push("/");
          alert("error");
        } else {
          res(d);
        }
      });
    });
  },

  fetchFranchise({ state, dispatch }, value) {
    return new Promise((res) => {
      state.loading = true;
      state.ident = value;
      dispatch("fetch", { url: "/guest/franchise", method: "get" }).then(
        (r) => {
          if (r.data.error) {
            res({ error: true, msg: "Invalid Merchant" });
          }
          // console.log("fran", r.data);
          state.franchise = r.data;

          //get order from local storage
          const dataOrder = localStorage.getItem('order');
          if(dataOrder) state.order = JSON.parse(dataOrder).filter((d)=>d.fire_id == state.franchise.fire_id);

          state.loading = false;
          res(r.data);
        }
      );
    });
  },

  fetchOutlet({ state }, value) {
    return new Promise((res) => {
      const item = {
        method: "get",
        url: `https://db.getorders.app/products.sl.${state.franchise.f_id}.${state.franchise.outlets[value].id}.json`,
        headers: {},
      };

      if (value.headers) {
        item.headers = { ...value.headers };
      } else {
        item.headers = {
          Code: state.ident || "",
          fire_id: state.franchise ? state.franchise.fire_id : "",
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": "EF95vGTnuo1GDqSQw6BmN41IR1G5R0Li8E5WIxlm",
        };
        if (state.invoice_id) {
          item.headers.order_id = state.invoice_id;
        }
      }

      axios(item).then((d) => {
        res(d);
      });
    });
  },

  fetchProduct({ state, dispatch }) {
    return new Promise((res) => {
      if (state.franchise.outlets.length > 0) {
        dispatch("fetchOutlet", 0).then((r) => {
          let a = r.data.filter((d) =>
            d.media_urls.length > 0 && d.tags.length > 0 ? true : false
          );
          // let a = r.data;
          let inclusive = r.data.map((d) => d.taxes).filter((d) => d.inclusive);

          if (inclusive.length > 0) {
            // code here
          }
          a = a.filter((d) => (d.tags.length > 0 ? true : false));
          //compare product & tag
          state.product = a.sort((a, b) =>
            a.tags[0].name.localeCompare(b.tags[0].name)
          );
          // state.product = a;
          state.loading = false;
          res(r.data);
        });
      } else
        dispatch("fetch", {
          url: "/guest/products?test=true",
          method: "get",
        }).then((r) => {
          var a = r.data;
          state.product = a.sort((a, b) =>
            a.tags[0].name.localeCompare(b.tags[0].name)
          );
          state.loading = false;
          res(r.data);
        });
    });
  },

  fetchUsers({ state }) {
    let i = localStorage.getItem("user");
    if (i == null) {
      state.users = [];
    } else {
      state.users = JSON.parse(i);
    }
  },

  saveUser({ state }, value) {
    if (value.status === null) {
      state.users.push(value.user);
      let a = state.users.map((d) => {
        if (d.selected == undefined) {
          d.selected = true;
          return { ...d };
        } else {
          d.selected = false;
          return { ...d };
        }
      });
      state.users = a;
      localStorage.setItem("user", JSON.stringify(state.users));
    } else {
      state.users[value.status] = {
        ...value.user,
        selected: state.users[value.status].selected,
      };
      localStorage.setItem("user", JSON.stringify(state.users));
    }
  },

  saveOrder({state},data){
    
      const dataOrder = localStorage.getItem('order');
      
      var old_order = [];

      if(dataOrder){
         old_order = JSON.parse(dataOrder);
       
      }
     
      old_order.push(data);
      

      localStorage.setItem('order',JSON.stringify(old_order));
      state.order = old_order;
    
  },
  


  insertUser({ state }, value) {
    value.selected = true;
    if (value.email && value.email.includes("%40")) {
      value.email.replace("%40", "@");
      state.users = [value];
    } else state.users = [value];
  },

  setUser({ state }, value) {
    let a = state.users.map((d, i) => {
      if (i == value) {
        d.selected = true;
        return { ...d };
      } else {
        d.selected = false;
        return { ...d };
      }
    });

    state.users = a;
    localStorage.setItem("user", JSON.stringify(state.users));
  },

  changeOutlet({ state, dispatch }, value) {
    return new Promise((res) => {
      dispatch("fetchOutlet", value).then((r) => {
        let a = r.data.filter((d) => (d.media_urls.length > 0 ? true : false));
        state.product = a.sort((a, b) =>
          a.tags[0].name.localeCompare(b.tags[0].name)
        );
        // state.product = r.data;
        state.loading = false;
        res(r.data);
      });
    });
  },

  fetchInvoice({ state, dispatch }, value) {
    state.invoice_id = value;
    dispatch("fetch", { url: "/guest/invoice", method: "get" }).then((r) => {
      state.invoice = r.data;
      state.loading = false;
    });
  },

  fetchGeocode({ state }, value) {
    state.loading = true;
    return new Promise((res) => {
      let address = `${value.address}, ${value.address2}`;
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${config.Google_API}`
        )
        .then((r) => {
          state.loading = false;
          if (r.data.status != "ZERO_RESULTS") {
            res({
              address: value.address,
              address2: value.address2,
              city: r.data.results[0].address_components.at(-4).long_name,
              state: r.data.results[0].address_components.at(-3).long_name,
              latitude: r.data.results[0].geometry.location.lat,
              longitude: r.data.results[0].geometry.location.lng,
            });
          } else {
            res(r.data.status);
          }
        });
    });
  },

  uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },

  generate({ state }, value) {
    var url = "";

    url = `https://api.whatsapp.com/send?phone=${state.franchise.phone}&text=`;

    let cust = "";
    if (value.delivery == true) {
      cust = `*${value.customer.name}*\n${value.customer.address}\n${value.customer.address2}\n${value.customer.postcode}`;
    } else {
      cust = `*${value.customer.name}*`;
    }
    if (value.notes != "") cust += `\n\nNotes : ${value.notes}`;
    var list = `*${
      value.delivery == true ? "DELIVERY" : "PICKUP"
    } from getorders.app*\n\n`;
    var msg = "To check invoice, tap here";
    list += `${state.franchise.outlet_name || state.franchise.name}\n\n`;
    list += `${msg} 👉🏻 https://v2.getorders.app/i/${value.invoice_id}\n\n`;

    value.cart.forEach((p) => {
      list += `*${p.name} x${p.quantity}*\n`;
      if (p.variant) {
        p.variant.forEach((v) => {
          list += ` _- ${v.name}_\n`;
        });
      }
      p.modifiers.forEach((m) => {
        list += ` _- ${m.name}_\n`;
      });
      if (p.remarks) {
        list += `_Remarks : ${p.remarks}_\n`;
      }
    });
    var txt = encodeURIComponent(list + "\n" + cust);
    return `${url}${txt}`;
  },

  checkout({ dispatch, rootGetters }, value) {
    let v = rootGetters["getVoucher"];
    if (v) {
      value.voucher = v.id;
    }

    dispatch("fetch", {
      url: "/guest/delivery",
      method: "post",
      data: value,
    }).then(async (r) => {
      value.invoice_id = r.data.invoice_id;
      if (r.data.checkoutURI) {
        document.location.href = r.data.checkoutURI;
      }
      else document.location.href = await dispatch("generate", value);
    });
  },

  saveReview({ dispatch }, value) {
    dispatch("fetch", {
      url: "/guest/review",
      method: "post",
      data: value,
    }).then((r) => {
      console.log(state.invoice);
      console.log(r);
    });
  },

  saveProductsReview({ dispatch }, value) {
    dispatch("fetch", {
      url: "/guest/product_review",
      method: "post",
      data: value,
    }).then((r) => {
      console.log(r);
    });
  },

  fetchReview({ state, dispatch }, value) {
    dispatch("fetch", { url: `/guest/product_review/${value}`, method: "get" }).then((r) => {
      state.productReview = r.data;
      state.loading = false;
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
