import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "./assets/global.scss";
import firebase from "firebase/app";
import "firebase/app";
import constant from "./constant";
import VueGtag from "vue-gtag";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGtag, {
  bootstrap: false,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: constant.Google_API,
    libraries: "places"
  },
});

firebase.initializeApp(constant.config);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  created() {
    let split = window.location.href.split("/");
    // console.log("split", split);
    if (split[3] == "i") {
      this.$router.push({
        name: "Page",
        params: { child: "i", page: split[4] },
      });
    } else if (split.length == 5 && split[4].includes("?k=")) {
      var queryVar = split[4].replace("?k=", "");
      this.$router.push({
        name: "Home",
        params: { child: split[3] },
        query: { k: queryVar },
      });
    } else
      this.$router.push({
        name: "Home",
        params: { child: split[3] },
      });
  },
  render: (h) => h(App),
}).$mount("#app");
